<template>
    <v-sheet class="custom-border border pa-4">
        <!-- <SingleUploadDialog 
            :dialog="dialog" 
            :title="'Upload proof of payment'"
            :file_types="['image/png', 'image/jpeg']" 
            :loading="loading3"
            :input_accept="'.png,.jpeg,.jpg'"
            @close="dialog = false"
            @submit="submitFile"/> -->
        <div class="poppins fw600 f18 secondary-1--text pa-3">Transaction History</div>
        <v-data-table
            :headers="transaction_history_tbl"
            class="poppins"
            :loading="loading"
            :items="invoices"
            hide-default-footer>
            <template v-slot:item.date="{ item }">
                {{  $dateFormat.dayDateTime(item.date) }}
            </template>
            <template v-slot:item.status="{ item }">
                <v-alert v-if="item.status === 1" dense color="#7BC14533" class="success--text ma-auto f14">SUCCESS</v-alert>
                <v-alert v-if="item.status === 2" dense color="#BDBDBD33" class="secondary-2--text ma-auto f14">PENDING</v-alert>
                <v-alert v-if="item.status === 3" dense color="#B5221033" class="error--text ma-auto f14">FAILED</v-alert>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn block v-if="item.status === 1" color="success" text class="f12" dense @click="$router.push({name: 'User Course Details', params: {id: item.uuid}})">
                    <v-icon class="mr-1" dense>
                        mdi-eye-outline
                    </v-icon>
                    View Course
                </v-btn>
                <v-btn block :loading="loading1" v-if="item.status === 3" color="warning" text class="f12" dense @click="addToList(item.course_id)">
                    <v-icon class="mr-1" dense>
                        mdi-text-box-outline
                    </v-icon>
                    Add to my List
                </v-btn>
                <span v-if="item.status === 2" class="f12 secondary-2--text" dense>
                    <v-icon class="mr-1" dense>
                        mdi-clock-outline
                    </v-icon>
                    For Approval
                </span>
                <!-- <v-btn block :loading="loading2" v-if="item.status === 2 && !item.nomination_form" color="error" text class="f12" dense @click="proceedToPayment(item.course_id)">
                    <v-icon class="mr-1" dense>
                        mdi-cash
                    </v-icon>
                    Proceed to payment
                </v-btn> -->
                <v-btn block :loading="loading2" v-if="item.status === 2 && item.nomination_form" color="error" text class="f12" dense @click="transaction = item, dialog = true">
                    <v-icon class="mr-1" dense>
                        mdi-upload-outline
                    </v-icon>
                    Upload Proof of Payment
                </v-btn>
            </template>
        </v-data-table>

        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginate"
            @page="(e) => { page = e, getTransactions()}" 
            @paginate="(e) => { paginate = e, page = 1, getTransactions()}"/>
    </v-sheet>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { transaction_history_tbl } from '@/constants/tblheaders/transactionhistory';
// import SingleUploadDialog from '@/components/reusable/forms/SingleUploadDialog.vue';

export default {
    // components: {
    //     SingleUploadDialog
    // },
    data: () => ({
        transaction_history_tbl,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        dialog: false,
        transaction: null,
        pageCount: 1,
        page: 1,
        paginate: '10'
    }),
    methods: {
        ...mapMutations(['alertMutation']),
        ...mapActions('usr', ['getTransactionHistory', 'addToCart', 'uploadProofOfPayment']),

        addToList(id) {
            this.loading1 = true
            this.addToCart({course_id: id}).then(() => {
                this.loading1 = false
                this.alertMutation({
                    show: true,
                    text: 'Added to List!',
                    type: "success"
                })
            })
        },

        proceedToPayment(id){
            this.loading2 = true
            let _form = new FormData()
            _form.append('course_id', id)

            const config = { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }

            this.$api.post(`/user/checkout`, _form, config).then(res => {
                if (res.data.hasOwnProperty('mode_of_payment')) {
                    this.loading2 = false
                    this.alertMutation({
                        show: true,
                        text: 'Enrollment Success! Redirecting you to dashboard...',
                        type: "success"
                    })
                    setTimeout(() => {
                        location.href = '/dashboard'
                        // this.getCoursesAction().then(() => {
                        //     this.otherCoursesAction().then(() => {
                        //         this.getCartItems().then(() => {
                        //             this.$router.push('/')
                        //         })
                        //     })
                        // })
                    }, 1000)
                } else {
                    var newDoc = document.implementation.createHTMLDocument();
                    var parsedContent = document.createElement('div');

                    var parser = new DOMParser();
                    var xmlDoc = parser.parseFromString(res.data, 'text/html');

                    parsedContent.appendChild(xmlDoc.documentElement);
                    newDoc.body.appendChild(parsedContent);
                    
                    var newTab = window.open();
                    newTab.document.write(newDoc.documentElement.outerHTML);

                    this.loading2 = false
                    location.href = '/dashboard'
                }
            }).catch(e => {
                if(e.response.status === 404) {
                    this.loading2 = false
                    this.alertMutation({
                        show: true,
                        text: 'Checkout is not available at this moment',
                        type: "error"
                    })
                    // this.errorPrompt = true
                    // this.checkoutLoading = false
                } else {
                    this.loading2 = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                }
            })
        },
        

        submitFile(e) {
            this.loading3 = true

            let _formData = new FormData();

            _formData.append("invoice_id", this.transaction.nomination_form.invoice_id);
            _formData.append("course_id", this.transaction.course_id);
            _formData.append("official_receipt_no", this.transaction.official_receipt_no);
            _formData.append("_method", "PUT");

            if(e) {
                _formData.append("image", e);
            }

            this.uploadProofOfPayment({invoice_id: this.transaction.nomination_form.invoice_id, form: _formData}).then(() => {
                this.loading3 = false
                this.transaction = null
                this.dialog = false
                this.alertMutation({
                    show: true,
                    text: 'Proof of payment successfully uploaded.',
                    type: "success"
                })
                this.getTransactions()
            }).catch(e => {
                console.log(e)
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong in uploading.',
                    type: "error"
                })
                this.loading3 = false
            })
        },

        getTransactions(){
            this.loading = true
            this.getTransactionHistory({ page: this.page, paginate: Number(this.paginate) }).then((res) => {
                this.pageCount = res.last_page
            }).finally(() => {
                this.loading = false
            })
        }

    },
    mounted() {
        this.getTransactions()
    },
    computed: {
        ...mapState('usr', {
            invoices: (state) => state.transaction_history
        })
    }
}
</script>